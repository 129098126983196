import { default as indexegOWm4p6gtMeta } from "/home/vsts/work/1/s/pages/index.vue?macro=true";
import { default as logini6Htc8i9t2Meta } from "/home/vsts/work/1/s/pages/login.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/vsts/work/1/s/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logini6Htc8i9t2Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/login.vue")
  }
]