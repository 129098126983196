<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
useHead({
  bodyAttrs: {
    class: 'bg-[#d7dde0]'
  },
  title: "Kundeportal | Miljøservice"
})

</script>